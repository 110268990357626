import "./index.css";

import { DAppProvider, Polygon } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

const INFURA_PROJECT_ID = "2a79d60d7d6740bf9fa7f94204c422b6";
const config = {
  readOnlyChainId: Polygon.chainId,
  readOnlyUrls: {
    [Polygon.chainId]: "https://polygon-mainnet.infura.io/v3/" + INFURA_PROJECT_ID,
  },
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
